import React from 'react'
import Slider from "react-slick";
import { NavLink } from 'react-router-dom';


import Banner_image from "../../assets/Section-background.png";
import Portfolio1 from "../../assets/Home-portfilio.png";
import celina from "../../assets/celina.png";
import yoga from "../../assets/yoga.png";
import elearning from "../../assets/elearning.png";
import haskapower from "../../assets/haskapower.png";
import build from "../../assets/build.jpeg";
import celebrate from "../../assets/celebrate.png";
import nested from "../../assets/nested.png";
import fashion from "../../assets/fashion.png";













export default function Bestportfolio() {

    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1 ,
        autoplay: true,
        autoplaySpeed: 2500,
      };


  return (

    <>
   
   <section className="section best-portfolio" id="Bestportfolio" style={{background: `url(${Banner_image})`,}}>
   <div className='container'>
      <div className="banner-wrapper">

          <div className='portfolio-wrap'>


          <Slider {...settings}>



            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <p className='tagline'>Ecommerce</p>
                    <p className='technology-name'>Shopify</p>
                    <h2 className='title' >Our Best Portfolio</h2>
                    <p className='content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <a className='btn' href=""> VIEW ALL</a>


                </div>
                <div className='image-wrapper'>
                    <img src={build} alt="Shopify" />
                </div>
            </div>


            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <p className='tagline'>Designing</p>
                    <p className='technology-name'>Adobe Photoshop</p>
                    <h2 className='title' >Our Best Portfolio</h2>
                    <p className='content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <a className='btn' href="http://localhost:3000/home"> VIEW ALL</a>
                </div>
                <div className='image-wrapper'>
                    <img src={fashion} alt="Shopify" />
                </div>
            </div>

            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <p className='tagline'>Ecommerce</p>
                    <p className='technology-name'>WordPress</p>
                    <h2 className='title' >Our Best Portfolio</h2>
                    <p className='content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <a className='btn' href="#"> VIEW ALL</a>
                </div>
                <div className='image-wrapper'>
                    <img src={celebrate} alt="Shopify" />
                </div>
            </div>


            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <p className='tagline'>Designing</p>
                    <p className='technology-name'>Adobe Photoshop</p>
                    <h2 className='title' >Our Best Portfolio</h2>
                    <p className='content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <a className='btn' href="http://localhost:3000/home"> VIEW ALL</a>
                </div>
                <div className='image-wrapper'>
                    <img src={elearning} alt="Shopify" />
                </div>
            </div>


            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <p className='tagline'>Designing</p>
                    <p className='technology-name'>Adobe Photoshop</p>
                    <h2 className='title' >Our Best Portfolio</h2>
                    <p className='content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <a className='btn' href="http://localhost:3000/home"> VIEW ALL</a>
                </div>
                <div className='image-wrapper'>
                    <img src={yoga} alt="Shopify" />
                </div>
            </div>


            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <p className='tagline'>Ecommerce</p>
                    <p className='technology-name'>Shopify</p>
                    <h2 className='title' >Our Best Portfolio</h2>
                    <p className='content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <a className='btn' href="http://localhost:3000/home"> VIEW ALL</a>
                </div>
                <div className='image-wrapper'>
                    <div className="image-wrapper-img">
                    <img src={haskapower} alt="Shopify" />
                    </div>
                </div>
            </div>




            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <p className='tagline'>Ecommerce</p>
                    <p className='technology-name'>Shopify</p>
                    <h2 className='title' >Our Best Portfolio</h2>
                    <p className='content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <a className='btn' href="http://localhost:3000/home"> VIEW ALL</a>
                </div>
                <div className='image-wrapper'>
                    <div className="image-wrapper-img">
                    <img src={celina} alt="Shopify" />
                    </div>
                </div>
            </div>
            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <p className='tagline'>Ecommerce</p>
                    <p className='technology-name'>Shopify</p>
                    <h2 className='title' >Our Best Portfolio</h2>
                    <p className='content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <a className='btn' href="http://localhost:3000/home"> VIEW ALL</a>
                </div>
                <div className='image-wrapper'>
                    <div className="image-wrapper-img">
                    <img src={nested} alt="Shopify" />
                    </div>
                </div>
            </div>




</Slider>



          </div>


        </div>
      </div>
 
      </section>



    </>
  )
}
