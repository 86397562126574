import React from 'react'
import Bestportfolio from './home-sections/Bestportfolio'
import Portfolio from './portfolio/Portfolio'
import Expectedfeatures from './service/Expectedfeatures'
import Customerssays from './home-sections/Customerssays'
import Partnerslogos from './home-sections/Partnerslogos'
import Popup from './home-sections/Popupm'



export default function portfolio() {
  return (
    <>
        <Bestportfolio/>
        <Portfolio/>
        <Expectedfeatures/>
        <Customerssays/>
        <Partnerslogos/>
        <Popup/>
  </>
  )
}
