import React from 'react'

import Banner_image2 from "../../assets/team-backgroud.png";

import Ankush from "../../assets/ankush.png";
import Pooja from "../../assets/pooja.png";
import Priya from "../../assets/priya.png";
import Pankaj from "../../assets/pankaj.png";
import Lalit from "../../assets/lalit.png";
import Rohit from "../../assets/rohit.png";
import Naveen from "../../assets/naveen.png";
import Ankasha  from "../../assets/ankasha.png";
import Pushp  from "../../assets/pushap.png";
import Mohit  from "../../assets/mohit.png";
import Reeta  from "../../assets/reeta.png";
import Vinay  from "../../assets/vinay.png";
import himanshu  from "../../assets/himanshu.png";
import him from "../../assets/him.png";




export default function Awesometeam() {

  return (

    <>
   
    <section className="section awesometeam-page image-left" id="Awesometeam" style={{background: `url(${Banner_image2})`,}}>
   <div className='container'>
      <div className="awesometeam-wrapper">
            <h2 className='title'>Meet Our Awesome Team</h2>
            <p className='content'>Top Team Members </p>
                
          <div className='awesometeam-wrap'>

            <div className='team-member-wrap'>
                <div className='content-wrapper'>
                <img src={Ankush} alt="Web Development" />
                    <h2 className='name' >Ankush Kumar</h2>
                    <p className='designation'>Founder/CEO</p> 
                </div>
            </div>

            <div className='team-member-wrap'>
                <div className='content-wrapper'>
                <img src={Pooja} alt="Web Development" />
                    <h2 className='name' >Pooja Kumari</h2>
                    <p className='designation'>Project Manager</p> 
                </div>
            </div>

            <div className='team-member-wrap'>
                <div className='content-wrapper'>
                <img src={Ankasha} alt="Web Development" />
                    <h2 className='name'>Ankasha Patyal</h2>
                    <p className='designation'>HR Executive</p> 
                </div>
            </div>

            <div className='team-member-wrap'>
                <div className='content-wrapper'>
                <img src={Pankaj} alt="Web Development" />
                    <h2 className='name' >Pankaj Patyal</h2>
                    <p className='designation'>Business manager</p> 
                </div>
            </div>
            <div className='team-member-wrap'>
                <div className='content-wrapper'>
                <img src={Pushp} alt="Web Development" />
                    <h2 className='name' >Pushp Thakur</h2>
                    <p className='designation'>Team Leader</p> 
                </div>
            </div>
            <div className='team-member-wrap'>
                <div className='content-wrapper'>
                <img src={Vinay} alt="Web Development" />
                    <h2 className='name' >Vinay Thakur</h2>
                    <p className='designation'>Project Coordinator</p> 
                </div>
            </div>
            <div className='team-member-wrap'>
                <div className='content-wrapper'>
                <img src={him} alt="Web Development" />
                    <h2 className='name' >Himanshu </h2>
                    <p className='designation'>Graphics Designer</p> 
                </div>
            </div>
            <div className='team-member-wrap'>
                <div className='content-wrapper'>
                <img src={Mohit} alt="Web Development" />
                    <h2 className='name' >Mohit Kumar</h2>
                    <p className='designation'>Shopify Developer</p> 
                </div>
            </div>

            <div className='team-member-wrap'>
                <div className='content-wrapper'>
                <img src={Lalit} alt="Web Development" />
                    <h2 className='name' >Lalit Singh</h2>
                    <p className='designation'>Frontend developer</p> 
                </div>
            </div>

            <div className='team-member-wrap'>
                <div className='content-wrapper'>
                <img src={Rohit} alt="Web Development" />
                    <h2 className='name' >Rohit Mehta</h2>
                    <p className='designation'>SEO Executive</p> 
                </div>
            </div>

            <div className='team-member-wrap'>
                <div className='content-wrapper'>
                <img src={Naveen} alt="Web Development" />
                    <h2 className='name' >Naveen Kumar</h2>
                    <p className='designation'>Graphic Designer</p> 
                </div>
            </div>

            <div className='team-member-wrap'>
                <div className='content-wrapper'>
                <img src={Priya} alt="Web Development" />
                    <h2 className='name' >Priya Kumari</h2>
                    <p className='designation'>Shopify Developer</p> 
                </div>
            </div>


            <div className='hidden-team'>

            

           

            <div className='team-member-wrap'>
                <div className='content-wrapper'>
                <img src={Reeta} alt="Web Development" />
                    <h2 className='name' >Reeta Kumar</h2>
                    <p className='designation'>Business Development Team</p> 
                </div>
            </div>
            
            


            </div>


          </div>
        </div>
      </div>
    </section>


    </>
  )
}
