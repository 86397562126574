import React from 'react'
import { NavLink } from 'react-router-dom';



import Design from "../../assets/services/1.png";
import Design_hover from "../../assets/services/01.png";

import Development from "../../assets/services/2.png";
import Development_hover from "../../assets/services/02.png";

import Appdevelopment from "../../assets/services/3.png";
import Appdevelopment_hover from "../../assets/services/03.png";

import Seo from "../../assets/services/4.png";
import Seo_hover from "../../assets/services/04.png";

import Social from "../../assets/services/5.png";
import Social_hover from "../../assets/services/05.png";

import Ppc from "../../assets/services/6.png";
import Ppc_hover from "../../assets/services/06.png";




export default function Ourservices() {
   
  return (

    <>
   
   <section className="section our-services" id="Ourservices" >
   <div className='container'>
      <div className="banner-wrapper">
          <h2 className='section-title'>Our Services</h2>
          <p className='section-content'>HubSyntax is here to help you with everything you need for great web design and development. We offer personalised and budget-friendly services.</p>


          <div className='ourservices-wrap'>

            <div className='service-wrap'>
                <div className='services-wrappers'>
                    <img className='off-hover' src={Design} alt="icon" />
                    <img className='on-hover' style={{display: "none"}}  src={Design_hover} alt="icon" />
                    <h4>UI/UX Design</h4>
                    <p>  Our team of UI/UX designers creates user-friendly interfaces that grab people's attention and keep them interested.We make sure your website is easy to use and looks great.</p>
                    <NavLink className="btn btn-primary btnhover" to="/services#Shopify">Explore now</NavLink>


                </div>
            </div>

            <div className='service-wrap'>
                <div className='services-wrappers'>
                    <img className='off-hover' src={Development} alt="Logo" />
                    <img className='on-hover' style={{display: "none"}}  src={Development_hover} alt="icon" />
                    <h4>Web Development</h4>
                    <p>Our web developers build functional websites using recent technologies adn innovative ideas. Websites are easy for people to navigate, whether you're on a computer, tablet, or phone. </p>
                    <NavLink className="btn btn-primary btnhover " to="/services">Explore now</NavLink>
                </div>
            </div>

            <div className='service-wrap'>
                <div className='services-wrappers'>
                    <img className='off-hover' src={Appdevelopment} alt="Logo" />
                    <img className='on-hover' style={{display: "none"}}  src={Appdevelopment_hover} alt="icon" />
                    <h4>App Development</h4>
                    <p>We create customized mobile apps that fulfill your specific needs, enhancing user engagement and expanding your reach. We design apps that make things smoother for who use them.
 </p>
                    <NavLink className="btn btn-primary btnhover" to="/services">Explore now</NavLink>
                </div>
            </div>

            <div className='service-wrap'>
                <div className='services-wrappers'>
                    <img className='off-hover' src={Seo} alt="Logo" />
                    <img className='on-hover' style={{display: "none"}}  src={Seo_hover} alt="icon" />
                    <h4>SEO Services</h4>
                    <p>  Our SEO strategies drive organic traffic to your website, making it easier for potential customers to find you on search engines. It's like putting up a sign that helps people notice you better.</p>
                    <NavLink className="btn btn-primary btnhover" to="/services">Explore now</NavLink>

                </div>
            </div>

            <div className='service-wrap'>
                <div className='services-wrappers'>
                    <img className='off-hover' src={Social} alt="Logo" />
                    <img className='on-hover' style={{display: "none"}}  src={Social_hover} alt="icon" />
                    <h4>Social Media Marketing</h4>
                    <p>Our team helps you do great on social media. We create plans that make more people know about your brand, enjoy what you post  and want to stay connected with you.</p>
                    <NavLink className="btn btn-primary btnhover" to="/services">Explore now</NavLink>
                </div>
            </div>

            <div className='service-wrap'>
                <div className='services-wrappers'>
                    <img className='off-hover' src={Ppc} alt="Logo" />
                    <img className='on-hover' style={{display: "none"}}  src={Ppc_hover} alt="icon" />
                    <h4>PPC Advertisement</h4>
                    <p>With our pay-per-click advertising service, people will notice your brand and bring good website visitors who are interested in what you have. Later they could become your customers. </p>
                    <NavLink className="btn btn-primary btnhover" to="/services">Explore now</NavLink>
                </div>
            </div>


          </div>


        </div>
      </div>

      </section>



    </>
  )
}
