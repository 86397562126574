import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import $ from 'jquery';
import './slick.css';
import './slick.js';
import './theme.js';


import App from './App';
import reportWebVitals from './reportWebVitals';



console.log('Slick Slider');

$('.autoplay').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
});
// popup function//////
$(document).on('click','.cross',function(e){
    e.preventDefault(); 
    $('.popup').hide();    
  });
  $(document).on('click','.appointment-button',function(e){
    e.preventDefault();
    $('.popup').show();   
  });

// submit btn/////
$(document).on('click','.new-btnn',function(e){
    e.preventDefault(); 
    $('.submit-msg').show().fadeOut(2000) ;    
  });


  $(document).on('click','.submit_popupnew',function(e){
    e.preventDefault(); 
    $('.submit-msg p').show().fadeOut(2000) ;    
  });

  $(document).on('click','.submitbtncontact',function(e){
    e.preventDefault(); 
    $('.alert_msg').show().fadeOut(2000) ;    
  });

// counter function////

 $('#Progress .counter').each(function () {
        $(this).prop('Counter',0).animate({
        Counter: $(this).text()
          }, 
        {
        duration: 3000,
        easing: 'swing',
        step: function (now) {
            $(this).text(Math.ceil(now));
        }
    });
  });




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


var a = 0;
$(window).scroll(function () {
    var oTop = $("#counter-box").offset().top - window.innerHeight;
    if (a === 0 && $(window).scrollTop() > oTop) {
        $(".counter").each(function () {
            var $this = $(this),
                countTo = $this.attr("data-number");
            $({
                countNum: $this.text()
            }).animate(
                {
                    countNum: countTo
                },
                {
                    duration: 3000,
                    easing: "swing",
                    step: function () {
                        //$this.text(Math.ceil(this.countNum));
                        $this.text(
                            Math.ceil(this.countNum).toLocaleString("en")
                        );
                    },
                    complete: function () {
                        $this.text(
                            Math.ceil(this.countNum).toLocaleString("en")
                        );
                        //alert('finished');
                    }
                }
            );
        });
        a = 1;
    }
});
