
import './App.css';
import ScrollToTop from "react-scroll-to-top";


import Header from './files/Header';
import Home from './files/Homepage';
import Services from './files/Services';
import Portfolio from './files/Portfolio';
import About from './files/About';
import Contact from './files/Contact';

import Footer from './files/Footer';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";



function App() {


  return (
    <>
    <div className='main'>
      <Router>
          <Header />

          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />

            <Redirect to="/" />
          </Switch>
          <ScrollToTop smooth />
          <Footer />
      </Router>
      </div>
    </>

  );
}

export default App;
