import React from 'react'
import Banner from './home-sections/Banner'
import TrustedClients from './home-sections/Trustedclients'
import Ourservices from './home-sections/Ourservices'
import Progress from './home-sections/Progress'
import Workprocess from './home-sections/Workprocess'
import Bestportfolio from './home-sections/Bestportfolio'
import Trustus from './home-sections/Trustus'
import Customerssays from './home-sections/Customerssays'
import Partnerslogos from './home-sections/Partnerslogos'
import Popup from './home-sections/Popupm'



export default function homepage() {

    

    return (
      <>
        <Banner/>
        <TrustedClients/>
        <Ourservices/>
        <Progress/>
        <Workprocess/>
        <Bestportfolio/>
        <Trustus/>
        <Customerssays/>
        <Partnerslogos/>
        <Popup/>
      </>
    )
}
