import React from 'react'

// import Research from "../../assets/Work-research.png";
// import Design from "../../assets/Work-design.png";
// import Development from "../../assets/Work-development.png";
// import Testing from "../../assets/Testing.png";
// import Launch from "../../assets/Launch.png";



import Research from "../../assets/working-process/1.png";
import Research_hover from "../../assets/working-process/01.png";

import Design from "../../assets/working-process/2.png";
import Design_hover from "../../assets/working-process/02.png";

import Development from "../../assets/working-process/3.png";
import Development_hover from "../../assets/working-process/03.png";

import Testing from "../../assets/working-process/4.png";
import Testing_hover from "../../assets/working-process/04.png";

import Launch from "../../assets/working-process/5.png";
import Launch_hover from "../../assets/working-process/05.png";







export default function Workprocess() {
  return (

    <>
   
   <section className="section work-process" id="Workprocess" >
   <div className='container'>
      <div className="banner-wrapper">
          <h2 className='section-title'>Working Process</h2>
          <h4 className='sub-title'>How We Work For Our Customers</h4>


          <div className='work-process-wrap'>
            <div className='img-wrap'>
                <div className='content-wrapper'>
                <div className='image'>
              <img className='off-hover' src={Research} alt="Logo" />
              <img className='on-hover' style={{display: "none"}} src={Research_hover} alt="Logo" />
              </div>
              </div>
              <p className='process-title'>Research</p>
              
            </div>
            <div className='img-wrap margen-style'>
            <div className='content-wrapper'>
            <div className='image'>
              <img className='off-hover' src={Design} alt="Logo" />
              <img className='on-hover' style={{display: "none"}} src={Design_hover} alt="Logo" />
              </div>
              </div>
              <p className='process-title'>Design</p>
              
            </div>
            <div className='img-wrap margen-style'>
            <div className='content-wrapper'>
            <div className='image'>
              <img className='off-hover' src={Development} alt="Logo" />
              <img className='on-hover' style={{display: "none"}} src={Development_hover} alt="Logo" />
              </div>
              </div>
              <p className='process-title'>Development</p>
              
            </div>
            <div className='img-wrap margen-style'>
            <div className='content-wrapper'>
            <div className='image'>
              <img className='off-hover' src={Testing} alt="Logo" />
              <img className='on-hover' style={{display: "none"}} src={Testing_hover} alt="Logo" />
              </div>
              </div>
              <p className='process-title'>Testing</p>
             
            </div>
            <div className='img-wrap'>
            <div className='content-wrapper'>
            <div className='image'>
              <img className='off-hover' src={Launch} alt="Logo" />
              <img className='on-hover' style={{display: "none"}} src={Launch_hover} alt="Logo" />
              </div>
              </div>
              <p className='process-title'>Launch</p>
             
            </div>
          </div>
          
        <div className='content-foo'>
        <p className='bottom-cntent'>Our clients and their business is important to us. From the very first day we started collecting data related to business and brand, apply it to provide exactly what you want.</p>
        <a className='btn' href="#">Request A Quote</a>
        </div>
        </div>
      </div>

      </section>



    </>
  )
}
