import React from 'react'

import Banner_image1 from "../../assets/Section-background.png";
import Banner_image2 from "../../assets/serive-background1.png";
import Banner_image3 from "../../assets/serive-background2.png";


import Design_icon from "../../assets/service-ui-ux-icon.png";
import Design from "../../assets/service-ui-ux.png";

import Development_icon from "../../assets/service-development.png";
import Development from "../../assets/service-web-development.png";

import Ecommerce_icon from "../../assets/service-ecommerce.png";
import Ecommerce from "../../assets/service-ecommerce-sol.png";

import Shopify_icon from "../../assets/service-shopify.png";
import Shopify from "../../assets/service-shopify-baner.png";

import Seo_icon from "../../assets/service-seo-icon.png";
import Seo from "../../assets/service-seo.png";



import Portfolio1 from "../../assets/Home-portfilio.png";



export default function Uiuxdesign() {

  return (

    <>
   
   <section className="section service-page image-right" id="Uiuxdesign" style={{background: `url(${Banner_image1})`,}}>
   <div className='container'>
      <div className="banner-wrapper">
          <div className='design-wrap'>
            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <p className='tagline'>Our Services</p>
                    <h2 className='title' >UI/UX Design</h2>
                    <p className='content'> We're here to make your online experience amazing with our UI/UX design service. We create designs that make websites and apps look fantastic and work smoothly. It's like having a great artist and a tech wizard on your team! So whether you're launching a new venture, revamping an existing platform, or seeking to leave a lasting impression, our UI/UX design service is here to transform your ideas into digital masterpieces. We make sure everything is easy to use, so you'll love exploring and using what we create.  </p>
                    <a className='btn hidebtn' href="#">Learn More</a>
                </div>
                <div className='image-wrapper'>
                    <div className='img'>
                        <img src={Design} alt="UI/UX Design" />
                        <div className='icon-wrapper'>
                    <img className='icon' src={Design_icon} alt="UI/UX Design Icon" />
                    </div>
                    </div>
                   
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section className="section service-page image-left" id="Webdevelopment" style={{background: `url(${Banner_image2})`,}}>
   <div className='container'>
      <div className="banner-wrapper">
          <div className='design-wrap'>
            <div className='img-wrap' id="counter-box">
                <div className='image-wrapper'>
                <div className='img'>
                    <img src={Development} alt="Web Development" />
                    <div className='icon-wrapper'>
                    <img className='icon' src={Development_icon} alt="Web Development Icon" />
                    </div>
                    </div>
                   
                </div>
                <div className='content-wrapper'>
                    <p className='tagline'>Our Services</p>
                    <h2 className='title' >Web Development</h2>
                    <p className='content'> We're here to help you shine on the internet! Our team is really good at making websites. We create websites that look great on all devices and are super easy to use. No matter if you want a basic website to show off your work or a fancy one that does a lot of things, we can do it for you. So whether you're taking your first steps into the digital realm or aiming to level up your existing website, let us be the artisans who transform your ideas into engaging online experiences. Together, we'll make sure you shine brilliantly on the vast canvas of the internet. </p>
                    <a className='btn hidebtn' href="#">Learn More</a>
                </div>

            </div>
          </div>
        </div>
      </div>
    </section>


    <section className="section service-page middle-section image-right" id="Ecommerc" style={{background: `url(${Banner_image3})`,}}>
   <div className='container'>
      <div className="banner-wrapper">
          <div className='design-wrap'>
            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <p className='tagline tagline_new'>Our Services</p>
                    <h2 className='title' >Ecommerce Solutions</h2>
                    <p className='content'>Our team can help you create online stores where you can sell your stuff. These stores will look so good that they'll make customers want to buy from you. We'll help you showcase your products in the best light possible. High-quality images and clear descriptions will make customers feel confident about what they're buying. And when they receive their orders and find everything as expected, that's a big win for everyone. We'll make sure the buying process is really easy and fun for your customers. This will make them happy and help you sell more things. </p>
                    <a className='btn hidebtn' href="#">Learn More</a>
                </div>
                <div className='image-wrapper'>
                <div className='img'>
                    <img src={Ecommerce} alt="Ecommerce" />
                    <div className='icon-wrapper'>
                    <img className='icon' src={Ecommerce_icon} alt="Ecommerce Icon" />
                    </div>
                    </div>
                   
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section className="section service-page image-left" id="Shopify" style={{background: `url(${Banner_image2})`,}}>
   <div className='container'>
      <div className="banner-wrapper">
          <div className='design-wrap'>
            <div className='img-wrap' id="counter-box">
                <div className='image-wrapper'>
                    <div className='img'>
                        <img src={Shopify} alt="Shopify" />
                        <div className='icon-wrapper'>
                        <img className='icon' src={Shopify_icon} alt="Shopify Icon" />
                        </div>
                    </div>
                   
                </div>
                <div className='content-wrapper'>
                    <p className='tagline'>Our Services</p>
                    <h2 className='title' >Shopify Experts</h2>
                    <p className='content'> Our Shopify experts will be there for you from the start to the finish. We'll set things up for you and even make your store look like your own style. We'll make sure your store is super appealing and convinces people to buy from you. That way, people who visit your store will become your regular customers. Our ultimate goal is to turn visitors into loyal customers. With our expertise, we'll craft an experience that not only encourages people to make their first purchase but also keeps them coming back for more. A well-designed store with an easy-to-navigate layout and a hassle-free checkout process can make all the difference. </p>
                    <a className='btn hidebtn' href="#">Learn More</a>
                </div>

            </div>
          </div>
        </div>
      </div>
    </section>


    <section className="section service-page image-right" id="Seoservice" style={{background: `url(${Banner_image1})`,}}>
   <div className='container'>
      <div className="banner-wrapper">
          <div className='design-wrap'>
            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <p className='tagline'>Our Services</p>
                    <h2 className='title' >SEO Services</h2>
                    <p className='content'> We offer special SEO services that make more people see your website. We do this by improving your website's content and how it's set up. This way, the people you want to reach can find you more easily when they search online. We'll also take care of how your website is set up behind the scenes. It's like arranging things in your shop so that everything is easy to find. This helps search engines like Google understand what your website is all about. When they understand it better, they're more likely to show it to people looking for exactly what you have. This brings more visitors to your website naturally and helps your brand grow. </p>
                    <a className='btn hidebtn' href="#">Learn More</a>
                </div>
                <div className='image-wrapper'>
                    <div className='img'>
                        <img src={Seo} alt="Seo" />
                        <div className='icon-wrapper'>
                        <img className='icon' src={Seo_icon} alt="Seo Icon" />
                        </div>
                    </div>
                    
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>



    </>
  )
}
