import React from 'react'
import { NavLink } from 'react-router-dom';

// import Design from "../../assets/Design-icon.png";
// import Development from "../../assets/Development-hover.png";
// import Appdevelopment from "../../assets/App-development.png";
// import Seo from "../../assets/Seo.png";



import Design from "../../assets/services/1.png";
import Design_hover from "../../assets/services/01.png";

import Development from "../../assets/services/2.png";
import Development_hover from "../../assets/services/02.png";

import Appdevelopment from "../../assets/services/3.png";
import Appdevelopment_hover from "../../assets/services/03.png";

import Seo from "../../assets/services/4.png";
import Seo_hover from "../../assets/services/04.png";





export default function Ourservices() {
  return (

    <>
   
   <section className="section about-our-services" id="Aboutservices" >
   <div className='container'>
      <div className="banner-wrapper">
          <h2 className='section-title'>Our Services</h2>
          <p className='section-content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>


          <div className='about-ourservices-wrap'>

            <div className='about-service-wrap'>
                <div className='about-services-wrappers'>
                    <div className='img-wrapper'>
                    <img className='off-hover' src={Design} alt="icon" />
                    <img className='on-hover' style={{display: "none"}}  src={Design_hover} alt="icon" />
                    </div>
                    <div className='content-wrapper'>
                    <h4>UI/UX Design</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. </p>
                    <NavLink className="btn btn-primary  " to="/services">Explore now</NavLink>
                    </div>
                </div>
            </div>

            <div className='about-service-wrap'>
                <div className='about-services-wrappers'>
                    <div className='img-wrapper'>
                    <img className='off-hover' src={Development} alt="Logo" />
                    <img className='on-hover' style={{display: "none"}}  src={Development_hover} alt="icon" />
                    </div>
                    <div className='content-wrapper'>
                    <h4>Web Development</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. </p>
                    <NavLink className="btn btn-primary  " to="/services">Explore now</NavLink>
                    </div>
                </div>
            </div>

            <div className='about-service-wrap'>
                <div className='about-services-wrappers'>
                <div className='img-wrapper'>
                <img className='off-hover' src={Appdevelopment} alt="Logo" />
                    <img className='on-hover' style={{display: "none"}}  src={Appdevelopment_hover} alt="icon" />
                    </div>
                    <div className='content-wrapper'>
                    <h4>App Development</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. </p>
                    <NavLink className="btn btn-primary  " to="/services">Explore now</NavLink>
                    </div>
                </div>
            </div> 

            <div className='about-service-wrap'>
                <div className='about-services-wrappers'>
                <div className='img-wrapper'>
                <img className='off-hover' src={Seo} alt="Logo" />
                    <img className='on-hover' style={{display: "none"}}  src={Seo_hover} alt="icon" />
                    </div>
                    <div className='content-wrapper'>
                    <h4>SEO Services</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. </p>
                    <NavLink className="btn btn-primary  " to="/services">Explore now</NavLink>
                    </div>
                </div>
            </div>
          </div>

      </div>
</div>
      </section>



    </>
  )
}
