import React,{ useRef } from 'react'
import { useState } from "react";
import emailjs from '@emailjs/browser';

import bnrimg from "../../assets/bnr-img.png";
import bnrimg1 from "../../assets/mail.png";
import bnrimg2 from "../../assets/phonee.png";
import bnrimg3 from "../../assets/close.png";

export default function Popupmail() {

	const form = useRef();

	const sendEmail = (e) => {
	  e.preventDefault();
  
	  emailjs.sendForm('service_ylhsj4u', 'template_csqn577', form.current, 'S_m4d5jaDBKylQRwP')
		.then((result) => {

			console.log(result.text);
		}, (error) => {
			console.log(error.text);
		});
	};



// ////////////////////

// const [firstName, setFirstName] = useState('');
// const [firstEmail, setFirstEmail] = useState('');
// const [firstPhone, setFirstPhone] = useState('');
// const [firstCompany, setFirstCompany] = useState('');
// const [firstCity, setFirstCity] = useState('');
// const [firstState, setFirstState] = useState('');
// const [firstSelect, setFirstSelect] = useState('');
// const [firstUrl, setFirstUrl] = useState('');
// const [firstText, setFirstText] = useState('');




// const handleChange = event => {
//     setFirstName(event.target.value);
// };
// const handleChangea = event => {
// 	setFirstEmail(event.target.value);
// };
// const handleChangeb = event => {
// 	setFirstPhone(event.target.value);
// };
// const handleChangec = event => {
//     setFirstCompany(event.target.value);
// };
// const handleChanged = event => {
//      setFirstCity(event.target.value);
// };
// const handleChangee = event => {
// 	setFirstState(event.target.value);
// };
// const handleChangef = event => {
// 	setFirstSelect(event.target.value);
// };
// const handleChangeg = event => {
// 	setFirstUrl(event.target.value);
// };
// const handleChangeh = event => {
// 	setFirstText(event.target.value);
// };


// const handleClick = () => {
//     // 👇️ clear input value
//     setFirstName('');
// 	setFirstEmail('');
// 	setFirstPhone('');
// 	setFirstCompany('');
// 	setFirstCity('');
// 	setFirstState('');
// 	setFirstSelect('');
// 	setFirstSelect('');
// 	setFirstUrl('');
// 	setFirstText('');
	
//   };






    return (

  
      <>


<section className='popup_sectipon'>
	<div className="popup" style={{display: "none"}}>
		<div className="container">
			<div className="wraper">
				<div className="cross">
				<img className='new-img' src={bnrimg3}/>
				</div>
				<div className="wraper-left">
					<div className="image">
                    <img className='new-img' src={bnrimg}/>
						<div className="details">
							<h1 className="heading">Our clients and their business is important to us.</h1>	
							<div className="number">
							<img className='mail-iconimg' src={bnrimg1}/>								
							<a href="mailto:info@hubsyntax.com"><p className="heading2">info@hubsyntax.com</p></a>
							</div>
							<div className="number">
							<img className='mail-iconimg' src={bnrimg2}/>
								<a href="tel:+918679227771"><p className="heading2">+91-8679227771</p>							</a>

								
								
							</div>									
						</div>
					</div>				
				</div>
				<div className="text">
					<div className="input-filed">
						<h2 className="heading3">Take the First Step Towards Accomplishing <span>Your Business Goals</span></h2>
					<div className="inputs">
						<form id="pop_mail" ref={form}  onSubmit={sendEmail}>
							<div className="name">
								<input type="text"  placeholder="Full Name" name="user_name" required/>
							</div>
							<div className="name">
								<input type="email"  placeholder="Email Address"  name='user_email'  required/>
							</div>
							<div className="name">
								<input type="tel"  placeholder="Phone Number"   name='user_phone'  required/>
							</div>
							<div className="name">
								<input type="text" name="company" placeholder="Company" />
							</div>
							<div className="name">
								<input type="text" name="city" placeholder="City" />
							</div>
							<div className="name">
								<input type="text" name="State" placeholder="State" />
							</div>
							<div className="name">
								<select id="select"  >
									<option value="India">India</option>
									<option value="USA">USA</option>
									<option value="China">China</option>
									<option value="Japan">Japan</option>
									<option value="Russia">Russia</option>
								</select>
							</div>
							<div className="name">
								<input type="url" name="Website Url" placeholder="Website URL"/>
							</div>
							<div id="textareacontact" className="name request">
								<textarea  placeholder='Enter here' > </textarea>
							</div>
							<div className="Submit submit_popupnew">
								<input type="Submit" className="submit" value="Submit Now"/>
							</div>
						</form>
						<div className="submit-msg msgpopup" >
                      <p style={{display: "none"}}>Submit Successfully.</p>
                    </div>
					
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    </section>
    </>
  )
  }
