import React from 'react'
import Uiuxdesign from './service/Design'
import Expectedfeatures from './service/Expectedfeatures'
import Customerssays from './home-sections/Customerssays'
import Partnerslogos from './home-sections/Partnerslogos'
import Popup from './home-sections/Popupm'




export default function services() {
  return (

    <>
        <Uiuxdesign/>
        <Expectedfeatures/>
        <Customerssays/>
        <Partnerslogos/>
        < Popup/>
    </>
  )
}
