import React from 'react'
import Slider from "react-slick";

import Comas from "../../assets/Comas.png";
import website from "../../assets/Customer-website.png";



export default function Bestportfolio() {

    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1 ,
        autoplay: true,
        autoplaySpeed: 2000,
      };


  return (

    <>
   
   <section className="section customer-says" id="Customersays">
   <div className='container'>
      <div className="banner-wrapper">



           <div className='content-section'>
            <div className='content-wrap'>
            <img src={Comas} alt="Shopify" />
            <h2 className='title'>What Our Customers Says</h2>
            <p className='content'>Our Client Voices: Our Services Exceed Expectations! Discover what our valued clients are saying about Hubsyntax.</p>
            </div>
            </div> 
          <div className='customers-wrap'>
          <div className='customers-wrap-inner'>
          <Slider {...settings}>
            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <div className='customer-logo'>
                        <img src={website} alt="Shopify" />
                    </div>

              <div class="review_section"> <a  className='website-name' target="_blank" rel="noreferrer" href="https://doussoutraore.com/">DOSOUSSOUTRAORE.COM</a> </div>
                    <p className='content'> "Hubsyntax did an excellent work on my Shopify website speed and performance improvement; fixing issues, running updates and theme customization. I strongly recommend  for  impressive competence.Great resource who understands business needs. Quality work always delivered on time. Truly a business partner motivated by quality work and results."</p>
                    <p className='customer-name'> Doussou Traore</p>
                </div>
            </div>
            

            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <div className='customer-logo'>
                        <img src={website} alt="Shopify" />
                    </div>
                    <div class="review_section"> <a  className='website-name' target="_blank" rel="noreferrer" href="#"></a> </div>
                    <p className='content'>  "Hubsyntax was extremely responsive to emails, questions and very thorough. Hubsyntax has excellent developer/designer, guided me through the Upwork process and helped me to understand the Shopify website, great and easy to work with. I look forward to hiring again!" </p>
                    <p className='customer-name'>Deborah Neasi-Miller</p>
                </div>
            </div>

            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <div className='customer-logo'>
                        <img src={website} alt="Shopify" />
                    </div>
                    <div class="review_section"> <a  className='website-name' target="_blank" rel="noreferrer" href="#">Zepyure.com</a> </div>
                    <p className='content'> "I would 100% work with Hubsyntax again, very knowledgeable and really informative With the  good Shopify insight.  Everything was perfect, from the communication, the timing and the quality of work. Hubsyntax has strong knowledge of Shopify, UI designs and optimizing the loading speed. Will definitely use Hubsyntax again for more work"</p>
                    <p className='customer-name'>Allen Huddleston</p>
                </div>
            </div>

            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <div className='customer-logo'>
                        <img src={website} alt="Shopify" />
                    </div>
                    <div class="review_section"> <a  className='website-name' target="_blank" rel="noreferrer" href="https://www.bluandgreen.com/">WWW.BLUANDGREEN.COM</a> </div>
                    <p className='content'> "Hubsyntax is very skilled and was able to create what we needed based on our designs. Hubsyntax created custom Shopify templates that we are very happy with.  has successfully delivered a well-designed Shopify theme that has met our expectations. I can proudly say that I selected the right developer for my website. They have great knowledge of Shopify , Custom Apps. The team routinely comes up with useful ideas that increased Conversion Rate."</p>
                    <p className='customer-name'> Kimberley Norcott</p>
                </div>
            </div>


            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <div className='customer-logo'>
                        <img src={website} alt="Shopify" />
                    </div>
                    <div class="review_section"> <a  className='website-name' target="_blank" rel="noreferrer" href="https://tigerfightprints.com/">TIGERFIGHTPRINTS.COM</a> </div>
                    <p className='content'> "Hubsyntax was fantastic - was professional, did all amendments where requested and even did extra at times. I will be working with Hubsyntax soon again on any needed extra work on my site.The team were fast, efficient and responsive. They built my Shopify stores updates to a high standard with smooth functionality. I wouldn't hesitate to use them again, would definitely recommend. 5 stars" </p>
                    <p className='customer-name'>Paul Bruwer</p>
                </div>
            </div>


            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <div className='customer-logo'>
                        <img src={website} alt="Shopify" />
                    </div>
                    <div class="review_section"> <a  className='website-name' target="_blank" rel="noreferrer" href="shop.fundraising.com">SHOP.FUNDRAISING.COM</a> </div>
                    <p className='content'> "Understands business goals very well, delivers great customer solutions that are robust and effective. Very competent. Completed task quickly and efficiently, very friendly and even did extra things for me" </p>
                    <p className='customer-name'>Marc Alcindor</p>
                </div>
            </div>


            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
                    <div className='customer-logo'>
                        <img src={website} alt="Shopify" />
                    </div>
                    <div class="review_section"> <a  className='website-name' target="_blank" rel="noreferrer" href="https://covingdirect.co.uk/">COVINGDIRECT.CO.UK</a> </div>
                    <p className='content'>  "Hubsyntax was a pleasure to deal with, has a professional approach to work and got the job done without any issues. I would defiantly hire Hubsyntax again for Shopify development work ." </p>
                    <p className='customer-name'>Emac hale</p>
                </div>
            </div>


</Slider>
</div>


          </div>


        </div>
      </div>
 
      </section>



    </>
  )
}
