import React from 'react'
import { NavLink } from 'react-router-dom'


export default function Ouroffice() {
  return (

    <>
   
   <section className="section looking-for" id="Lookingfor">
   <div className='container'>
   <div className="looking-wrapper">
        <h2 className='title'>Are you Looking for a Project Development</h2>
        <p className='content'>We are your dedicated partners, committed to helping you bring your vision to life and elevate your brand to new heights. Let's collaborate and create a future where your ideas become reality. </p>
        <NavLink className="lets-talk-btn btn" to="#">Let's Talk</NavLink>
      </div>
 </div>
      </section>
    </>
  )
}