import React from 'react'

import Banner_image2 from "../../assets/serive-background1.png";

import Team from "../../assets/hubsyntax-team.png";



export default function Uiuxdesign() {

  return (

    <>
   
    <section className="section service-page image-left" id="Webdevelopment" style={{background: `url(${Banner_image2})`,}}>
   <div className='container'>
      <div className="banner-wrapper">
          <div className='design-wrap'>
            <div className='img-wrap' id="counter-box">
                <div className='image-wrapper'>
                <div className='img'>
                    <img src={Team} alt="Web Development" />
                    </div>
                   
                </div>
                <div className='content-wrapper'>
                    <p className='tagline'>About</p>
                    <h2 className='title' >Why HubSyntax</h2>
                    <p className='content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p> 
                 
                    <p className='content'> Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                
                </div>

            </div>
          </div>
        </div>
      </div>
    </section>


    </>
  )
}
