import React from 'react'
import Client1 from "../../assets/shopifypic.png";
// import Client2 from "../../assets/Footer-bing.png";
import Client3 from "../../assets/Footer-design.png";
// import Client4 from "../../assets/Google-Analytics-Logo.png";
// import Client5 from "../../assets/Footer-adward.png";
import Client6 from "../../assets/adobe.png";
import Client7 from "../../assets/shopifyplus.png";
import Client8 from "../../assets/hubspot1.png";
import Client9 from "../../assets/webflow.png";
import Client10 from "../../assets/figma.png";
import Client11 from "../../assets/woocommerce.png";
import Client12 from "../../assets/cloudfare.png";
import Client13 from "../../assets/klaviyo.png";
import Client14 from "../../assets/pagefly.png";
import Client15 from "../../assets/ga4.png";
import Client16 from "../../assets/terpay.png";
import Client17 from "../../assets/affirm.png";
import Client18 from "../../assets/pixel.png";
import Client19 from "../../assets/googleads1.png";





import Slider from "react-slick";



export default function Trustedclients() {

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1 ,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };






  return (

    <>
   
   <section className="section partners-logos" id="Partnerslogos" >
   <div className='container'>
      <div className="banner-wrapper">
          <div className='brandslogos-wrap autoplay'>
            <Slider {...settings}>
            <div className='img-wrap'>
              <img src={Client1} alt="Logo" />
            </div>
            {/* <div className='img-wrap margen-style'>
              <img src={Client2} alt="Logo" />
            </div> */}
            <div className='img-wrap margen-style'>
              <img src={Client3} alt="Logo" />
            </div>
            {/* <div className='img-wrap margen-style'>
              <img src={Client4} alt="Logo" />
            </div> */}
            {/* <div className='img-wrap'>
              <img src={Client5} alt="Logo" />
            </div> */}
           
            <div className='img-wrap margen-style'>
              <img src={Client6} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client7} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client8} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client9} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client10} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client11} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client12} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client13} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client14} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client15} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client16} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client17} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client18} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client19} alt="Logo" />
            </div>
            </Slider>
          </div>


        </div>
      </div>

      </section>



    </>
  )
}
