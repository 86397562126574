import React from 'react'
import { useRef } from 'react'
import emailjs from '@emailjs/browser';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios'

import { NavLink } from 'react-router-dom';

import Background from "../assets/footer-background.png";
import Logo from "../assets/logo.png";



import Facebook from '../assets/social-icons/faebook.png';
import Instagram from '../assets/social-icons/instagram.png';
import Linkedin from '../assets/social-icons/linked-in.png';
import Printrest from '../assets/social-icons/pinterest.png';
// import Email from '../assets/social-icons/email.png';

//import Facebook_hover from '../assets/social-icons/faebook-1.png';
//import Instagram_hover from '../assets/social-icons/instagram-1.png';
//import Linkedin_hover from '../assets/social-icons/linked-in-1.png';
//import Printrest_hover from '../assets/social-icons/pinterest-1.png';
//import Email_hover from '../assets/social-icons/email-1.png';


export default function Footer() {

  // const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs.sendForm('service_ylhsj4u', 'template_csqn577', form.current, 'S_m4d5jaDBKylQRwP')
  // 	.then((result) => {
  // 		console.log("data",result);
  // 	}, (error) => {
  // 		console.log(error.text);
  // 	});
  // };

  // /////////////////////////////////////

  const [recipient, setRecipient] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('/sendEmail', {
        to: email,
        subject: subject,
        text: body

      });

      if (response.data === 'Email sent successfully') {
        console.log('Email sent successfully');
      } else {
        console.log('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      console.log('An error occurred while sending the email');
    }
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <section className="section footer" id="Footer" style={{ background: `url(${Background})`, }}>

        <div className='container'>
          <div className='footer-wrapper'>

            <div className='footer-blocks'>
              <div className='block-wrap'>
                <img className="footr-logocls" src={Logo} alt="Footer Logo" />
                <p className='content'>Fuel your company's growth journey with our extraordinary IT solutions and marketing expertise that soars your brand's visibility.</p>
                <div className='social-sharing'>
                  <ul>
                    <li><a className="socail-link" target="_blank" rel="noreferrer" href="https://www.facebook.com/HubSyntax/"><img src={Facebook} alt="Facebook" /></a></li>
                    <li><a className="socail-link" target="_blank" rel="noreferrer" href="https://www.instagram.com/hubsyntax/"><img src={Instagram} alt="Instagram" /></a></li>
                    <li><a className="socail-link" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/hubsyntax?originalSubdomain=in"><img src={Linkedin} alt="Linkedin" /></a></li>
                    <li><a className="socail-link" target="_blank" rel="noreferrer" href="https://in.pinterest.com/hubsyntax/"><img src={Printrest} alt="Printrest" /></a></li>
                    {/* <li><a className="socail-link" to="mailto:info@hubsyntax.com"><img src={Email} alt="E-mail" /></a></li> */}
                  </ul>
                </div>

                <div className='for-call'>
                  <NavLink className="socail-link" to="tel:+91867922771">
                    <p className='label'>For Projects, Call</p>
                    <p className='contact-number' to="tel:+91-9876543210">+91-86792 27771</p>



                  </NavLink>
                </div>


              </div>
            </div>

            <div className='footer-blocks'>
              <div className='block-wrap'>
                <h2 className='footer-title'>Quick Links</h2>
                <ul>

                  <li ><NavLink className="footer-nav-link" to="/home" onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }} >Home</NavLink></li>
                  <li><NavLink className="footer-nav-link" to="/services" onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}>Services</NavLink></li>
                  <li><NavLink className="footer-nav-link" to="/portfolio" onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }} >Portfolio</NavLink></li>
                  <li><NavLink className="footer-nav-link" to="/about" onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}>HubSyntax</NavLink></li>
                  <li><NavLink className="footer-nav-link" to="/contact" onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}>Contact</NavLink></li>
                </ul>
              </div>
            </div>


            <div className='footer-blocks'>
              <div className='block-wrap'>
                <h2 className='footer-title'>Our Office</h2>
                <div className='address'>
                  <div className='line-items'>
                    <label>Address 1 :</label><p>1600D Dove St, Newport Beach,<br />CA 92660, United States</p>
                  </div>
                  <div className='line-items'>
                    <label>Address 2 :</label><p>E-203, NH 5, Phase 8B, <br /> Industrial Area, Sector 74,<br />Sahibzada Ajit Singh Nagar,<br />Punjab 140308​ </p>
                  </div>
                  <div className='line-items'>
                    <label>E-mail:</label><p><NavLink className="mail-link" to="mailto:info@hubsyntax.com">info@hubsyntax.com</NavLink></p>
                  </div>
                  <div className='line-items'>
                    <label>Phone:</label><p><NavLink className="call-link" to="tel:+91867922771"></NavLink>+91-86792 27771</p>
                  </div>
                </div>
              </div>
            </div>



            <div className='footer-blocks'>
              <div className='block-wrap'>
                <h2 className='footer-title'>Contact Us</h2>
                <div className='contact-form'>
                  <form onSubmit={handleSubmit}>
                    <ul>
                      <li><input type="text" className='form-input name' id="Name" name='user_name' placeholder='Your Name' value={recipient} onChange={(e) => setRecipient(e.target.value)} required /></li>
                      <li><input type="email" className='form-input email' id="Email" name='user_email' placeholder='Your Email' value={email} onChange={(e) => setEmail(e.target.value)} required /></li>
                      <li><input type="text" className='form-input subject' id="Subject" name='subject' placeholder='Subject' value={subject} onChange={(e) => setSubject(e.target.value)} required /></li>
                      <li><textarea name="message" className='form-input' id="Message" value={body} onChange={(e) => setBody(e.target.value)} placeholder='Your Message' rows="3"></textarea></li>
                      {/* <li><input className='submit-contact-from btn new-btnn' type="submit" value="Submit Now" /></li> */}
                      <button className='btnsubmit' type="submit">Submit Now</button>
                    </ul>
                  </form>
                  <div className="submit-msg" style={{ display: "none" }}>
                    <p>Submit Successfully.</p>
                  </div>
                </div>
              </div>
            </div>

            {/* 
            <div>
              <h1>Email Form</h1>
              <form onSubmit={handleSubmit}>
                <label htmlFor="recipient">Recipient:</label>
                <input type="name"  value={recipient} onChange={(e) => setRecipient(e.target.value)} required /><br /><br />
                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required /><br /><br />
                <label htmlFor="subject">Subject:</label>
                <input type="text" id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} required /><br /><br />

                <label htmlFor="body">Body:</label><br />
                <textarea id="body" value={body} onChange={(e) => setBody(e.target.value)} rows="4" cols="50" required /><br /><br />

                <button type="submit">Send Email</button>
              </form>
            </div> */}



          </div>
        </div>


        <div className='footer-copyright'>
          <p className='text-center'> ©2018-2023 hubsyntax. All rights reserved</p>
        </div>





      </section>

    </>

  )
}
