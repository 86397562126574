import React from 'react'
import { NavLink } from 'react-router-dom';

import Address_icon from "../../assets/address.png";
import Email_icon from "../../assets/contact-email.png";
import Phone_icon from "../../assets/phone.png";

export default function Ouroffice() {
  return (

    <>
   
   <section className="section our-office" id="Ouroffice">
   <div className='container'>
   <div className="office-wrapper">
        <h2 className='title'>Our Office</h2>
    
    <div className='address-wrap'>
        <div className='address-block'>
            <div className='address'>
                <ul>
                    <li>
                        <span className='icon'> <img src={Address_icon} alt="Address" /></span>
                        <span className='address-content'>1600D Dove St, Newport Beach, CA<br/> 92660, United States</span>
                        </li>
                    <li>
                        <span className='icon'><img src={Address_icon} alt="Address" /></span>
                        <span className='address-content'> E-203, NH 5, Phase 8B, Industrial Area, Sector 74,<br/>Sahibzada Ajit Singh Nagar.</span>
                    </li>
                    <li>
                        <span className='icon'><img src={Email_icon} alt="Address" /></span>
                        <span className='address-content'>
                            <a href="mailto:info@hubsyntax.com">info@hubsyntax.com</a>
                        </span>
                    </li>
                    <li>
                        <span className='icon'><img src={Phone_icon} alt="Address" /></span>
                        <span className='address-content'>
                            <a href="tel:+91-86792 27771">+91-86792 27771</a>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div className='map-block'>
            <div className='map'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3320.7091784017557!2d-117.86799408450327!3d33.66469664550008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcdf63c6cba6a7%3A0x266cecb03e3cd4cc!2sHubSyntax!5e0!3m2!1sen!2sin!4v1674226957434!5m2!1sen!2sin" width="600" height="480"></iframe>
            </div>
        </div>
    </div>


      </div>
 </div>
      </section>



    </>
  )
}