import React from 'react'
import Customerssays from './home-sections/Customerssays'
import Partnerslogos from './home-sections/Partnerslogos'
import Contactform from './contact/Contactform'
import Ouroffice from './contact/Ouroffice'
import Lookingfor from './contact/Lookingfor'
import Popup from './home-sections/Popupm'

export default function contact() {
  return (
    <>
        <Contactform/>
        <Ouroffice/>
        <Lookingfor/>
        <Customerssays/>
        <Partnerslogos/>
        <Popup/>
        </>
  )
}
