import React from 'react'
// import Client1 from "../../assets/Client-1.png";
// import Client2 from "../../assets/Client-2.png";
// import Client3 from "../../assets/Client-3.png";
// import Client4 from "../../assets/Client-4.png";
// import Client5 from "../../assets/Client-5.png";




import Client1 from "../../assets/clientslogo/Balloonelle.png";
import Client2 from "../../assets/clientslogo/Bang cookies.png";
import Client3 from "../../assets/clientslogo/Bind and boxes.png";
import Client4 from "../../assets/clientslogo/Celina.png";
import Client5 from "../../assets/clientslogo/EWS.png";
import Client6 from "../../assets/clientslogo/gowa.png";
import Client7 from "../../assets/clientslogo/Naked co.png";
import Client8 from "../../assets/clientslogo/nested.png";
import Client9 from "../../assets/clientslogo/Shea.png";
import Client10 from "../../assets/clientslogo/The rich barber.png";
import Client11 from "../../assets/clientslogo/Zeroharm.png";




import Slider from "react-slick";

export default function Trustedclients() {

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1 ,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (

    <>
   
   <section className="section tursted-clients" id="Turstedclients" >
   <div className='container'>
      <div className="banner-wrapper">
          <h2 className='section-title'>Trusted Clients</h2>


          <div className='brandslogos-wrap autoplay'>

<Slider {...settings}>
            <div className='img-wrap'>
              <img src={Client1} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client2} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client3} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client4} alt="Logo" />
            </div>
            <div className='img-wrap'>
              <img src={Client5} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client6} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client7} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client8} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client9} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client10} alt="Logo" />
            </div>
            <div className='img-wrap margen-style'>
              <img src={Client11} alt="Logo" />
            </div>
            </Slider>
          </div>


        </div>
      </div>

      </section>



    </>
  )
}
