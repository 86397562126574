import React from 'react'
import { NavLink } from 'react-router-dom';

// import Prrofit from "../../assets/Prrofit-drive.png";
// import Trustworthy from "../../assets/Trustworthy.png";
// import Deliver from "../../assets/Deliver-on-time.png";


import Prrofit from "../../assets/customers-trust/1.png";
import Prrofit_hover from "../../assets/customers-trust/01.png";

import Trustworthy from "../../assets/customers-trust/2.png";
import Trustworthy_hover from "../../assets/customers-trust/02.png";

import Deliver from "../../assets/customers-trust/3.png";
import Deliver_hover from "../../assets/customers-trust/03.png";




export default function Trustus() {
  return (

    <>
   
   <section className="section trust-us" id="Trustus" >
   <div className='container'>
      <div className="banner-wrapper">
          <h2 className='section-title'>Why Customers Trust Us?</h2>
          <p className='section-content'>Our proven track record of turning ideas into success stories is backed by satisfied clients who rely on us for exceptional digital solutions.</p>


          <div className='trustus-wrap'>

            <div className='trust-wrap'>
                <div className='trust-wrappers'>
                    <img className='off-hover' src={Prrofit} alt="Logo" />
                    <img className='on-hover' style={{display:"none"}} src={Prrofit_hover} alt="Logo" />
                    <h4>Profit-Driven Results</h4>
                    <p>We create strategies and designs that impress visually and convert visitors into loyal customers.</p>
                    
                </div>
            </div>

            <div className='trust-wrap'>
                <div className='trust-wrappers'>
                    <img className='off-hover' src={Trustworthy} alt="Logo" />
                    <img className='on-hover' style={{display:"none"}} src={Trustworthy_hover} alt="Logo" />
                    <h4>Trustworthy Services</h4>
                    <p>Transparency and honesty are at our core. Our clients trust us for reliable services that elevate their brand's online presence.</p>
                   
                </div>
            </div>

            <div className='trust-wrap'>
                <div className='trust-wrappers'>
                    <img className='off-hover' src={Deliver} alt="Logo" />
                    <img className='on-hover' style={{display:"none"}} src={Deliver_hover} alt="Logo" />
                    <h4>We Deliver on Time</h4>
                    <p>We value your time which is why we work hard to finish projects perfectly and deliver them on time.</p>
                </div>
            </div>


          </div>


        </div>
      </div>

      </section>



    </>
  )
}
