import React from 'react'
import { NavLink } from 'react-router-dom'

import Banner_image from "../../assets/Main-Banner.png";

import Magento from "../../assets/Magento.png";
import Mailchimp from "../../assets/Mailchimp.png";
import Wordpress from "../../assets/Wordpress.png";
import Wix from "../../assets/Wix.png";
import Shopify from "../../assets/Shopify.png";
import React_logo from "../../assets/React.png";
import Photoshop from "../../assets/Photoshop.png";
import Laravel from "../../assets/Laravel.png";
import Jquery from "../../assets/Jquery.png";
import Html5 from "../../assets/Html5.png";
import Hubsyntax_logo from "../../assets/Hubsyntax-logo.png";
import Elementor from "../../assets/Elementor.png";
import Ai from "../../assets/Ai.png";
import webflow from "../../assets/webflowic.png";
import klaviyo from "../../assets/klaviyopic.png";


import Banner_shape from "../../assets/Banner-bg1.png";
import Banner_shape1 from "../../assets/Banner-bg1.png";
import Banner_shape2 from "../../assets/Banner-bg2.png";
import Banner_shape3 from "../../assets/Banner-bg3.png";
import Banner_shape4 from "../../assets/Banner-bg4.png";
import Line_bg from "../../assets/Line-bg.png";



export default function Banner() {
    return (
    <section className="section banner" id="Banner"  style={{background: `url(${Banner_image})`,}}>
      <div className="banner-wrapper">

            <div className='background-spin-images'>
                <img className='small-shape' src={Banner_shape} />
                <img className='small-shape1' src={Banner_shape1} />
                <img className='small-shape2' src={Banner_shape2} />
                <img className='small-shape3' src={Banner_shape3} />
                <img className='small-shape4' src={Banner_shape4} />
            </div>
          <div className='banner-content'>
            <div className='container'>
                <div className='main-banner'>

                    <div className='content-side'>
                        <h4 className='sub-heading'>WE ARE SPECIALIZED IN</h4>


   


                        <h1 className='heading'> 
                        <div className= "heading_animation_wrapper "> 
                        <div className= "heading_animation "> 
                          <span className='colored first_colored'>Development &</span> 
                          {/* <div class="text-animation heading">
                           <div class="text-wrapper">
                                <h2 className="colored">Development &</h2>
                                <h2 className="colored">Development &</h2>
                             </div>
                          </div> 
                              <div class="container">
                                   <span data-title="Development &" class="text">Development &</span>
                               </div> */}
       
                       </div> 
                            </div>
                            <span className='normal'>
                             Web Designing</span></h1>
                        <p className='description'>Boost your company's brand and prominence with our best IT Solutions and Marketing services. We create, plan, and build excellent websites.</p>
                        <NavLink className="btn btn-primary " to="/contact">LET'S DO TOGETHER</NavLink>
                    </div>

                    <div className="services-icons">
                        <div className='icons_side'>
                            <div className="icon_container">

                                <ul className="icons">
                                    <li className="circle-logo1">
                                        <img src={webflow} alt="Logo"/>
                                    </li>
                                    <li className="circle-logo2">
                                        <img src={Photoshop} alt="Logo"/>
                                    </li>
                                    <li className="circle-logo3">
                                        <img src={Wix} alt="Logo"/>
                                    </li>
                                    <li className="circle-logo4">
                                        <img src={React_logo} alt="Logo"/>
                                    </li>
                                    <li className="circle-logo5">
                                        <img src={Shopify} alt="Logo"/>
                                    </li>
                                    <li className="circle-logo6">
                                        <img src={Wordpress} alt="Logo"/>
                                    </li>
                                    <li className="circle-logo7">
                                        <img src={klaviyo} alt="Logo"/>
                                    </li>
                                    <li className="circle-logo8">
                                        <img src={Jquery} alt="Logo"/>
                                    </li>
                                    <li className="circle-logo9">
                                        <img src={Ai} alt="Logo"/>
                                    </li>
                                    <li className="circle-logo10">
                                        <img src={Elementor} alt="Logo"/>
                                    </li>
                                    <li className="circle-logo11">
                                        <img src={Html5} alt="Logo"/>
                                    </li>
                                    <li className="circle-logo12">
                                        <img src={Laravel} alt="Logo"/>
                                    </li>
                                </ul>
                                <div className="logo-center">
                                    <ul className="main-logo">
                                        <li className="center-logo">
                                            <img src={Hubsyntax_logo} alt="Logo"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
 
                    
                </div>
            </div>
        </div>
    </div>
</section>
)
}
