import React from 'react'
import Banner_shape1 from "../../assets/Banner-bg1.png";
import Banner_shape2 from "../../assets/Banner-bg2.png";



export default function Progress() {
  return (

    <>
   
   <section className="section progress-section" id="Progress" >
   <div className='progress-spin-images'>
        <img className='progress-small-shape1' src={Banner_shape1} />
        <img className='progress-small-shape2' src={Banner_shape2} />
    </div>
   <div className='container'>
      <div className="banner-wrapper">

          <div className='progress-wrap'>

            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
             <h2 className='progress-number' ><span className='counter' data-number="100">100</span>+</h2>
             <p className='title'>Satisfied Clients</p>
             </div>
            </div>
            <div className='img-wrap' id="counter-box">
            <div className='content-wrapper'>
             <h2 className='progress-number'><span className='counter' data-number="500">500</span>+</h2>
             <p className='title'>Projects</p>
             </div>
            </div>
            <div className='img-wrap' id="counter-box">
            <div className='content-wrapper'>
             <h2 className='progress-number'><span className='counter' data-number="8">8</span>+</h2>
             <p className='title'>Years Experience</p>
             </div>
            </div>
            <div className='img-wrap' id="counter-box">
            <div className='content-wrapper'>
             <h2 className='progress-number'><span className='counter' data-number="150">150</span>+</h2>
             <p className='title'>App Development</p>
             </div>
            </div>
          </div>


        </div>
      </div>
 
      </section>



    </>
  )
}
