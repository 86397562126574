import React from 'react'
import $ from "jquery";
import { NavLink } from "react-router-dom";
import Logo from "../assets/hubsyntaxlogo.png";
import hamburger from "../assets/hamburger.png";
import cancel from "../assets/close.png";
import mobilebnr from "../assets/mobilebannerimg.png";


export default function header() {
// $(document).on('click','.hamburger-wrap img',function(e){
  
//     e.preventDefault();
//    $('.hamburger-menu-sec').toggle();
//     $('.hamburger-wrap img').hide();
// });
// $(document).on('click','.cancel-btn',function(e){
//   e.preventDefault();

//    $('.hamburger-menu-sec').hide(); 
//     $('.hamburger-wrap img').show();
// });

$(document).on('click','.hamburger-wrap',function(e){
  e.preventDefault(); 
  $('.hamburger-menu-sec').show();
      $('.hamburger-wrap').hide();
      $('.cancel-btn').show();

});

$(document).on('click','.cancel-btn',function(e){
  e.preventDefault(); 
  $('.cancel-btn').hide();
      $('.hamburger-menu-sec').hide();
  $('.hamburger-wrap').show();
  

});
$(document).on('click','.hambtn',function(e){
  e.preventDefault(); 
 
  $('.popup').show();
  

});

$(document).on('click','.mobile-menu',function(e){
  e.preventDefault(); 
 
  $('.hamburger-menu-sec').hide();
      $('.hamburger-wrap').show();

  

});


  return (
    <>
    <section className="section header" id="Header"> 
      <div className="container"> 

        <nav className="navbar navbar-expand-lg bg-light">
          <div className="container-fluid">
            <div className="logo">
              <NavLink className="navbar-brand" to="/home"> <img src={Logo} alt='' /></NavLink>
            </div>
            <div className="navigation">
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink activeClassName="active" className="nav-link" aria-current="page" to="/home">HOME</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink activeClassName="active" className="nav-link" to="/services">SERVICES</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink activeClassName="active" className="nav-link" to="/portfolio">PORTFOLIO</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink activeClassName="active" className="nav-link" to="/about">HUBSYNTAX</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink activeClassName="active" className="nav-link" to="/contact">CONTACT</NavLink>
                  </li>
                  <li className='appointment-button'>
                    <button type="button" className="btn btn-primary">REQUEST A QUOTE</button>
                  </li>
                </ul>
              </div>
            </div>
            <div className='hamburger-icon'>
              <div className='hamburger-wrap'>
              <img src={hamburger} alt='' />
              </div> 
            </div>
          </div>
        </nav>


            <div className='hamburger-menu-sec'  style= {{backgroundImage: `url(${mobilebnr})`, display:'none'}} >
              
                <ul>
                  <li> <NavLink  className='mobile-menu' aria-current="page" to="/home">HOME</NavLink> </li>
                  <li> <NavLink className='mobile-menu' aria-current="page" to="/services">SERVICES </NavLink></li>
                  <li> <NavLink className='mobile-menu' aria-current="page" to="/portfolio">PORTFOLIO </NavLink> </li>
                  <li> <NavLink className='mobile-menu' aria-current="page" to="/about">HUBSYNTAX </NavLink> </li>
                  <li> <NavLink className='mobile-menu' aria-current="page" to="/contact">CONTACT </NavLink> </li>
                  <li className='hambtn mobile-menu-btn'><button className="btn">REQUEST A QUOTE</button></li>
                  
                </ul> 
                <div className='cancel-btn'  style= {{display: 'none'}} >
                <img src={cancel} alt='' /> </div>
              </div>
              </div>
      
    </section>
    </>
  )
}
