import React from 'react'

import Research from "../../assets/service-team.png";
import Design from "../../assets/service-professional.png";
import Development from "../../assets/service-clean-quility.png";
import Testing from "../../assets/service-uptodate.png";
import Launch from "../../assets/service-no-execuse.png";
import coordination from "../../assets/servie-coordination.png";


export default function Expectedfeatures() {
  return (

    <>
   
   <section className="section service-page work-process" id="Workprocess" >
   <div className='container'>
      <div className="banner-wrapper">
          <h2 className='section-title'>Expected Features</h2>
          <h4 className='sub-title'>As an expert agency, we are highlighting our few core work standard features. </h4>


          <div className='work-process-wrap'>

            <div className='img-wrap'>
                <div className='content-wrapper'>
                    <div className='image'>
                        <img src={Research} alt="Logo" />
                    </div>
                </div>
                <p className='process-title'>Experienced <br/> & Dedicated Team</p>
            </div>

            <div className='img-wrap margen-style'>
                <div className='content-wrapper'>
                    <div className='image'>
                        <img src={Design} alt="Logo" />
                    </div>
                </div>
                <p className='process-title'>Highly Professional <br/> & Friendly</p>
            </div>

            <div className='img-wrap margen-style'>
                <div className='content-wrapper'>
                    <div className='image'>
                        <img src={Development} alt="Logo" />
                    </div>
                </div>
                <p className='process-title'>Clean & Quality <br/> Code</p>
            </div>

            <div className='img-wrap margen-style'>
                <div className='content-wrapper'>
                    <div className='image'>
                        <img src={Testing} alt="Logo" />
                    </div>
                </div>
                <p className='process-title'>Up to date <br/> Technologies</p>
            </div>

            <div className='img-wrap'>
                <div className='content-wrapper'>
                    <div className='image'>
                        <img src={Launch} alt="Logo" />
                    </div>
                </div>
                <p className='process-title'>No excuses <br/> just make it done</p>
            </div>

            <div className='img-wrap'>
                <div className='content-wrapper'>
                    <div className='image'>
                        <img src={coordination} alt="Logo" />
                    </div>
                </div>
                <p className='process-title'>100% effort and <br/> Coordination</p>
            </div>

          </div>
          
        <div className='content-foo'>
        <a className='btn' href="#">Request A Quote</a>
        </div>
        </div>
      </div>

      </section>



    </>
  )
}
