import React from 'react'
import { NavLink } from 'react-router-dom';
import $ from "jquery";



import Design from "../../assets/service-ui-ux.png";
import Development from "../../assets/service-web-development.png";
import Appdevelopment from "../../assets/service-ecommerce-sol.png";
import Social from "../../assets/service-shopify-baner.png";
import Seo from "../../assets/service-seo.png";
import yoga from "../../assets/yoga.png";
import closeportfolio from "../../assets/closeport.png";
import build from "../../assets/build.jpeg";
import haskapower from "../../assets/haskapower.png";
import haskafull from "../../assets/haskafull.png";
import buildfull from "../../assets/buildfull.png";
import celina from "../../assets/celina.png";
import celinafull from "../../assets/celinafull.jpeg";
import celebrate from "../../assets/celebrate.png";
import celebratefull from "../../assets/celebratefull.png";
import nested from "../../assets/nested.png";
import nestedheartfull from "../../assets/nestedheartfull.jpg";



import dal from "../../assets/dal.png";
import dalhalf from "../../assets/dalhalf.png";
import fashion from "../../assets/fashion.png";
import shot1 from "../../assets/shot1.png";
import shot2 from "../../assets/shot2.png";
import shot3 from "../../assets/shot3.png";
import shot4 from "../../assets/shot4.png";
import shot5 from "../../assets/shot5.png";



export default function Portfolio() {

    $(document).on('click','.our-portfolio .portfolio-wrap .service-wrap .services-wrappers',function(e){
        e.preventDefault(); 
            $('.portfolio_popup').show();
            var html = $(this).find('.full_page_img').html();
            $('.portfolio_popup .full_page_img').html(html);
      });


      $(document).on('click','.portfolio_popup .portfolio_popup_wrap .portfolio_popup_img',function(e){
        e.preventDefault(); 
            $('.portfolio_popup').hide();
      });

    
  return (

    <>
   
   <section className="section our-portfolio" id="Portfolio" >
   <div className='container'>
      <div className="banner-wrapper">
          <h2 className='section-title'>Other Portfolio</h2>


          <div className='portfolio-wrap'>

            <div className='service-wrap' >
                <div className='services-wrappers'>
                    <img src={haskapower} alt="Logo" />
                    <div className='cntent-wrapper' style={{display: `none`,}}>
                        <div className='wrapper'>
                            <div className="content">
                            <h4>Shopify</h4>
                            <p>Landing Page</p>
                            </div>
                        </div>
                    </div>
                    <div className="full_page_img"  data_id="1"  style={{display: `none`,}}>
                   <img className="fill"  src={haskafull} alt="" />
                </div>
                </div>
            </div>

            <div className='service-wrap'>
                <div className='services-wrappers'>
                    <img src={build} alt="Logo" />
                    <div className='cntent-wrapper' style={{display: `none`,}}>
                        <div className='wrapper'>
                        <div className="content">
                    <h4>Shopify</h4>
                    <p>Website</p>
                    </div>
                    </div>
                    </div>
                    <div className="full_page_img"  data_id="2" style={{display: `none`,}}>
                   <img className="fill"   src={buildfull} alt="" />
                </div>
                   
                </div>
            </div> 

            <div className='service-wrap'>
                <div className='services-wrappers'>
                    <img src={celina} alt="Logo" />
                    <div className='cntent-wrapper' style={{display: `none`,}}>
                        <div className='wrapper'>
                        <div className="content">
                    <h4>Shopify</h4>
                    <p>Website</p>
                    </div>
                   </div>
                   </div>
                   <div className="full_page_img" data_id="3" style={{display: `none`,}}>
                   <img className="fill"   src={celinafull} alt="" />
                </div>
                </div>
            </div>

            <div className='service-wrap' >
                <div className='services-wrappers'>
                    <img src={celebrate} alt="Logo" />
                    <div className='cntent-wrapper' style={{display: `none`,}}>
                        <div className='wrapper'>
                        <div className="content">
                            <h4> Shopify </h4>
                            <p>Website</p>
                        </div> 
                    </div>
               </div>
               <div className="full_page_img" data_id="4" style={{display: `none`,}}>
                   <img className="fill"  src={celebratefull} alt="" />
                </div>
                    
                </div>
            </div>

            <div className='service-wrap'>
                <div className='services-wrappers'>
                    <img src={dalhalf} alt="Logo" />
                    <div className='cntent-wrapper' style={{display: `none`,}}>
                        <div className='wrapper'>
                        <div className="content">
                    <h4>Shopify</h4>
                    <p>Website</p>
                    </div>
                    </div>
                    </div>
                    <div className="full_page_img"  data_id="5" style={{display: `none`,}}>
                   <img className="fill"   src={dal} alt="" />
                   </div>
                </div>
            </div>

             <div className='service-wrap'>
                <div className='services-wrappers'>
                    <img src={fashion} alt="Logo" />
                    <div className='cntent-wrapper' style={{display: `none`,}}>
                        <div className='wrapper'>
                        <div className="content">
                    <h4>Adobe Design</h4>
                    <p> Best Shot</p>
                    </div>
                    </div>
                    </div>
                    <div className="full_page_img"  data_id="5" style={{display: `none`,}}>
                  <div className=" multiple_shot_img">   
                   <img className="fill"   src={shot1} alt="" />
                   </div>    
                   <div className=" multiple_shot_img">   
                   <img className="fill"   src={shot2} alt="" />
                   </div>
                   <div className=" multiple_shot_img">   
                   <img className="fill"   src={shot3} alt="" />
                   </div>
                   <div className=" multiple_shot_img">   
                   <img className="fill"   src={shot4} alt="" />
                   </div>
                   <div className=" multiple_shot_img">   
                   <img className="fill"   src={shot5} alt="" />
                   </div>
                   </div>
                   
                </div>
            </div>

            <div className='service-wrap'>
                <div className='services-wrappers'>
                    <img src={nested} alt="Logo" />
                    <div className='cntent-wrapper' style={{display: `none`,}}>
                        <div className='wrapper'>
                        <div className="content">
                    <h4>Shopify</h4>
                    <p>Landing Page</p>
                    </div>
                    </div>
                    </div>
                    <div className="full_page_img"  data_id="5" style={{display: `none`,}}>
                   <img className="fill"   src={nestedheartfull} alt="" />
                   </div>
                    
                </div>
            </div>
{/* 
            <div className='service-wrap'>
                <div className='services-wrappers'>
                    <img src={Appdevelopment} alt="Logo" />
                    <div className='cntent-wrapper' style={{display: `none`,}}>
                        <div className='wrapper'>
                        <div className="content">
                    <h4>WordPress</h4>
                    <p>Website Name</p>
                    </div>
                    </div>
                    </div>
                    
                </div>
            </div>

            <div className='service-wrap' data_id="">
                <div className='services-wrappers'>
                    <img src={Appdevelopment} alt="Logo" />
                    <div className='cntent-wrapper' style={{display: `none`,}}>
                        <div className='wrapper'>
                        <div className="content">
                    <h4>WordPress</h4>
                    <p>Website Name</p>
                    </div>
                    </div>
                    </div>
                </div>
            
            </div>  */}


          </div>


        </div>
      </div>
      <div className="portfolio_popup" style={{display: `none`,}}> 
      <div className="portfolio_popup_wrap">
        <div className="portfolio_popup_img"> 
        <img class="" src={closeportfolio}/>
        </div>
        <div class="full_page_img">
        
        </div>
      </div>
      </div>    
      </section>



    </>
  )
}
