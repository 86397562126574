import React from 'react'
import Banner_image from "../../assets/Section-background.png";




export default function About() {
  return (

    <>
   
   <section className="section about-progress-section" id="AboutProgress" style={{background: `url(${Banner_image})`,}}>
   <div className='container'>
      <div className="banner-wrapper">
            <h1 className='title'>About Us</h1>
            <p className='content'>Helping our clients operate at their maximum potential since 2017</p>

          <div className='progress-wrap'>
            <div className='img-wrap' id="counter-box">
                <div className='content-wrapper'>
             <h2 className='progress-number' ><span className='counter' data-number="100">100</span>+</h2>
             <p className='title'>Satisfied Clients</p>
             </div>
            </div>
            <div className='img-wrap' id="counter-box">
            <div className='content-wrapper'>
             <h2 className='progress-number'><span className='counter' data-number="500">500</span>+</h2>
             <p className='title'>Projects</p>
             </div>
            </div>
            <div className='img-wrap' id="counter-box">
            <div className='content-wrapper'>
             <h2 className='progress-number'><span className='counter' data-number="8">8</span>+</h2>
             <p className='title'>Years Experience</p>
             </div>
            </div>
            <div className='img-wrap' id="counter-box">
            <div className='content-wrapper'>
             <h2 className='progress-number'><span className='counter' data-number="150">150</span>+</h2>
             <p className='title'>App Development</p>
             </div>
            </div>
          </div>


        </div>
      </div>
 
      </section>



    </>
  )
}
