import React,{ useRef } from 'react'
import emailjs from '@emailjs/browser';


import Banner_image from "../../assets/Section-background.png";


export default function ContactForm() {


  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ylhsj4u', 'template_csqn577', form.current, 'S_m4d5jaDBKylQRwP')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };


  return (

    <>
   
   <section className="section contact-from" id="Contactform" style={{background: `url(${Banner_image})`,}}>
   <div className='container'>
      <div className="banner-wrapper">

          <div className='form-wrap'>
            <h1 className='title'>Contact Us</h1>
            <p className='content'>Got a project in mind? We’d love to hear about it. Take five minutes to fill out our project form so that we can get to know you and understand your project.</p>


            <div className='contact-form-wrapper'>
                <div className='form-wrapper'>

                    <h2 className='form-heading'>Take the First Step Towards Accomplishing</h2>
                    <h2 className='form-heading-col'>Your Business Goals</h2>

                    <form action="" id='ContactForm' ref={form} onSubmit={sendEmail}>

                        <div className='filed-wrap'>
                            <label>First Name</label>
                            <input type="text" placeholder='Enter your first name' name="user_name" required/>
                        </div>
                        <div className='filed-wrap'>
                            <label>Last Name</label>
                            <input type="text" placeholder='Enter your last name' name='last_name' required/>
                        </div>
                        <div className='filed-wrap'>
                            <label>Email Address</label>
                            <input type="email" placeholder='Enter your email address' name='user_email' required/>
                        </div>
                        <div className='filed-wrap'>
                            <label>Phone Number</label>
                            <input type="text" placeholder='Enter your phone number' name='user_phone' required/>
                        </div>
                        <div className='filed-wrap full-width'>
                            <label>Message</label>
                            <textarea name="message" id="" rows="10" placeholder='Enter here' required></textarea>
                        </div>
                        <div className="Submitbtn">
								            <input className="btn submitbtncontact" type="submit" value="Submit Now"/>
							            </div>
                    </form>
                    <div className="alert_msg"  style={{display: "none"}}>
                      <p>Submit Successfully.</p>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      </section>

    </>
  )
 }




















// import React from 'react'
// import { NavLink } from 'react-router-dom';
// import Banner_image from "../../assets/Section-background.png";


// export default function Contactform() {


//   return (

//     <>
   



//   <section className="section contact-from" id="Contactformm" style={{background: `url(${Banner_image})`,}}>
//    <div className='container'>
//       <div className="banner-wrapper">

//           <div className='form-wrap'>
//             <h1 className='title'>Contact Us</h1>
//             <p className='content'>Got a project in mind? We’d love to hear about it. Take five minutes to fill out our project form so that we can get to know you and understand your project.</p>


//             <div className='contact-form-wrapper'>
//                 <div className='form-wrapper'>

//                     <h2 className='form-heading'>Take the First Step Towards Accomplishing</h2>
//                     <h2 className='form-heading-col'>Your Business Goals</h2>

//                     <form action="" id='ContactForm'>

//                         <div className='filed-wrap'>
//                             <label>First Name</label>
//                             <input type="text" placeholder='Enter your first name' name='first_name'/>
//                         </div>
//                         <div className='filed-wrap'>
//                             <label>Last Name</label>
//                             <input type="text" placeholder='Enter your last name' name='last_name'/>
//                         </div>
//                         <div className='filed-wrap'>
//                             <label>Email Address</label>
//                             <input type="email" placeholder='Enter your email address' name='email'/>
//                         </div>
//                         <div className='filed-wrap'>
//                             <label>Phone Number</label>
//                             <input type="text" placeholder='Enter your phone number' name='phone'/>
//                         </div>
//                         <div className='filed-wrap full-width'>
//                             <label>Message</label>
//                             <textarea name="message" id="" rows="10" placeholder='Enter here'></textarea>
//                         </div>

//                         <a href="#" value="save" id="Formsubmit_btn" className='btn form_submit'>Submit Now</a>


//                     </form>
//                 </div>
//             </div>


//           </div>


//         </div>
//       </div>
 
//       </section>



//     </>
//   )
// }