import React from 'react'
import About from './about-us/About'
import Whyhubsyntax from './about-us/Whyhubsyntax'
import Ourservices from './about-us/Ourservices'
import Workprocess from './home-sections/Workprocess'
import Awesometeam from './about-us/Awesometeam'
import Customerssays from './home-sections/Customerssays'
import Partnerslogos from './home-sections/Partnerslogos'
import Popup from './home-sections/Popupm'


export default function about() {
  return (
   <>
    <About/>
    <Whyhubsyntax/>
    <Awesometeam/>
    <Ourservices/>
    <Workprocess/>
    <Customerssays/>
    <Partnerslogos/>
    <Popup/>
   </>
  )
}
